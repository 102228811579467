/* ----------------------------------------------------------------
	Custom CSS

	Add all your Custom Styled CSS here for New Styles or
	Overwriting Default Theme Styles for Better Handling Updates
-----------------------------------------------------------------*/
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

.select-search__options {
    list-style: none;
}

.select-search__option {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

@media (max-width: 767.98px) {
    .hideOnMobile {
        display: none;;
    }
}

.page-menu-itemCustom {
    list-style: none;
    float: left;
    margin-top: 5px;
    margin-bottom: 5px;
}

.page-menu-pretext-custom {
    color: white;
    float: left;
    margin: 17px 10px 18px 0px;
}

#page-menu-wrap{
    position: relative!important;
}

@media (max-width: 767.98px) {
    .page-menu-title {
        margin-top: 10px;    
    }
}

.page-menu-title .donate {
    margin-left: 10px;
}

.page-menu-title {
    margin-top: 2px;
}

@media (max-width: 1200px) {
    .page-menu-title {
        font-size: 1rem!important;
    }
}

@media (max-width: 767.98px) {
    .page-menu-title .donate a {
        padding: 5px 10px;
    }
    .page-menu-title .donate {
        display: block;
        margin-left: 0;
    }
}